import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/CssBaseline/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/styles/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/next-auth/react.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/theme.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/shared/components/FacebookPixel.tsx")